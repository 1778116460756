body {
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 60px;
}

html {
    position: relative;
    min-height: 100%;
}
.dgray {
    background-color: dimgray;
}

.colorwhite {
    color: white;
}

.colorwhite:hover {
    color: black;
}

.home {
    font-size: 20px;
}

#banner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding-top: 15em;
    padding-bottom: 3em;
}

#banner2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    padding-top: 12em;
    padding-bottom: 3em;
}

.faq {
    .kaartje {
        margin-top: 15px;
    }

    .card-header:hover {
        color: black;
        background: lightblue;
        font-weight: bold;
    }

}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    line-height: 60px; /* Vertically center the text there */
}

#rnummer{
    display: none;
}
#mail{
    display: none;
}
